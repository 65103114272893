import React, { Component } from "react";
import "../Css/About.css";
import "bootstrap/dist/css/bootstrap.css";

class About extends Component {
  state = {};
  render() {
    return (
      <div style={{ paddingTop: 100, paddingBottom: 20 }} className="bg-light">
        <div className="container min-vh-100">
          <div className="row">
            <h2 className="text-nowrap">
              <span className="text-danger">Work</span> Style
            </h2>
          </div>

          <div className="row text-format about-shadow bg-white">
            <div className="col-lg-6 col-md-6 col-sm-6 info-tab inner-top-left box">
              <img
                className="image-size"
                alt=""
                src={process.env.PUBLIC_URL + "clean_code.png"}
              />
              <h5 className="fw-bold">Clean Code</h5>
              <br />
              <p className="justify">
                Well Defined Modules, <span className="text-danger">SOLID</span>{" "}
                Principles Adherence, Most suitable{" "}
                <span className="text-danger">Code Architecture</span>, In
                deapth Understanding of Composition vs Inheritance Usuage, Most
                appropriate UI/UX libraries to use, TTD and more... <br /> Do
                they strike your mind before you start coding ? The diverse
                subjectivity of these decisions is as challenging and engaging
                as coding itself. And I cherrish this phase of code development.
                An equally Engaged Client turns a project into a most thought
                provking <span className="text-danger">art</span> work. And I am
                Game for the same.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 info-tab inner-top-right box">
              <img
                className="image-size"
                alt=""
                src={process.env.PUBLIC_URL + "client.png"}
              />
              <h5 className="fw-bold">People</h5>
              <br />
              <p className="justify">
                The most important factor after work type are people. Even if
                the work is minimal(which it is, practically many times), the
                people we work with make it more enjoyable and a pleasent
                experience. This includes{" "}
                <span className="text-danger">Clients</span> and our
                <span className="text-danger">Co Workers</span>. Because work is
                not just sitting on the system and finishing a job. Work
                instigates learning. And bonding. And Awesome People around you
                increase it 10 times.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 info-tab inner-bottom-left box">
              <img
                className="image-size"
                alt=""
                src={process.env.PUBLIC_URL + "balance.png"}
              />
              <h5 className="fw-bold">The Balance</h5>
              <br />
              <p className="justify">
                The Right Balance of{" "}
                <span className="text-danger">Professionalism</span> and{" "}
                <span className="text-danger">Sincerity</span>. Often those who
                become too passionate loose the job. And those who become too
                adherent loose the cause. I give a lot of emphasis on these two
                factors when dealing with any situtation. This makes my
                communication with people very easy and relatable. Good
                communication helps us understanding the problem 50% faster.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 info-tab inner-end-bottom-right box">
              <img
                className="image-size"
                alt=""
                src={process.env.PUBLIC_URL + "problem_solving.png"}
              />
              <h5 className="fw-bold">Problem Solving</h5>
              <br />
              <p className="justify">
                While pursuing Engineering, it has come to my realisation that
                it wasn't just Textbook subjects which we had learned over time.
                What has developed along side, in every Engineer, is the Problem
                Solving <span className="text-danger">MindSet</span>, and the{" "}
                <span className="text-danger">Attitude</span> for it. These two
                key factors drive the motivation to solve random, unique
                problems and requirements for Clients. I recognize this and
                devote much of my time in keeping up these two axes in my
                arsenal, sharp and ready.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
