import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";

class Company extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("Data", this.props.company);
    return (
      <div>
        <h5>{this.props.company.name}</h5>

        <h6 className="fst-italic fw-lighter text-danger">
          {this.props.company.duration}
        </h6>
      </div>
    );
  }
}

export default Company;
