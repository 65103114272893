import React, { Component } from "react";

class Project extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h5 className="text-wrap text-format">{this.props.project.name}</h5>
        <ul>
          {this.props.project.description.map((point) => (
            <li className="text-wrap" key={point}>
              {point}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Project;
