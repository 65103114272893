import React, { Component, createRef } from "react";
import { BsFillStarFill } from "react-icons/bs";
import "../Css/Skills.css";
import "bootstrap/dist/css/bootstrap.css";
import Skill from "./Skill";

class Skills extends Component {
  state = {
    selectedId: 0,

    skillList: [
      {
        id: 1,
        name: "Android",
        type: "Framework",
        rating: 5,
        icon: process.env.PUBLIC_URL + "ic_android.svg",
      },
      {
        id: 2,
        name: "Core Java",
        type: "Language",
        rating: 3,
        icon: process.env.PUBLIC_URL + "ic_java.png",
      },
      {
        id: 3,
        name: "Kotlin",
        type: "Language",
        rating: 5,
        icon: process.env.PUBLIC_URL + "ic_kotlin.png",
      },
      {
        id: 4,
        name: "Coroutines",
        type: "Language Feature",
        rating: 5,
        icon: process.env.PUBLIC_URL + "ic_coroutines.png",
      },
      {
        id: 5,
        name: "MVVM Architecture",
        type: "Framework Feature",
        rating: 5,
        icon: process.env.PUBLIC_URL + "ic_mvvm.png",
      },
      {
        id: 6,
        name: "Java Script",
        type: "Language",
        rating: 2,
        icon: process.env.PUBLIC_URL + "ic_js.png",
      },
      {
        id: 7,
        name: "Type Script",
        type: "Language",
        rating: 2,
        icon: process.env.PUBLIC_URL + "ic_ts.png",
      },
      {
        id: 8,
        name: "React",
        type: "Language Library",
        rating: 2,
        icon: process.env.PUBLIC_URL + "ic_react.png",
      },
      {
        id: 9,
        name: "Angular 6",
        type: "Framework",
        rating: 1,
        icon: process.env.PUBLIC_URL + "ic_angluar.png",
      },
      {
        id: 10,
        name: "Node.js",
        type: "Framework",
        rating: 1,
        icon: process.env.PUBLIC_URL + "ic_node_js.png",
      },
      {
        id: 11,
        name: "React Native",
        type: "Language Library",
        rating: 1,
        icon: process.env.PUBLIC_URL + "ic_react_native.png",
      },
    ],
  };

  getStars = (rating) => {
    return (
      <span style={{ padding: 5 }}>
        <BsFillStarFill
          className={this.starColor(
            rating === 1 ||
              rating === 2 ||
              rating === 3 ||
              rating === 4 ||
              rating === 5
          )}
        />
        <BsFillStarFill
          className={this.starColor(
            rating === 2 || rating === 3 || rating === 4 || rating === 5
          )}
        />
        <BsFillStarFill
          className={this.starColor(
            rating === 3 || rating === 4 || rating === 5
          )}
        />
        <BsFillStarFill
          className={this.starColor(rating === 4 || rating === 5)}
        />
        <BsFillStarFill className={this.starColor(rating === 5)} />
      </span>
    );
  };

  starColor = (isSelected) => {
    if (isSelected) return "text-warning";
    else return "text-dark";
  };

  onHandleSkillSelection = (selectedId) => {
    this.setState({ selectedId });
  };

  renderSkills = (searchText) => {
    return this.state.skillList
      .filter((skill) =>
        skill.type.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((skill) => (
        <div
          key={skill.id}
          className="col-lg-3 col-sm-3 col-md-3 col-skills-style d-flex justify-content-center"
        >
          <div>
            <div className="col-content-skills-style">
              <Skill skill={skill} selectSkill={this.onHandleSkillSelection} />
            </div>
            <div className="w-100 d-flex justify-content-center">
              {this.getStars(skill.rating)}
            </div>
          </div>
        </div>
      ));
  };

  render() {
    return (
      <div
        style={{ paddingTop: 100, paddingBottom: 20 }}
        className="min-vh-100 position-relative"
      >
        <h2 className="container text-nowrap">
          Work <span className="text-danger">Skills</span>
        </h2>
        <div className="position-relative">
          <div className="border-skill-background bg-light"></div>
          <div className="position-absolute top-0 start-0 container skill-container">
            <div className="row row-skills-style">
              {this.renderSkills("Language")}
            </div>
            <div className="row row-skills-style">
              {this.renderSkills("Framework")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
