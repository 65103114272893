import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Css/Navbar.css";

class NavigationBar extends Component {
  render() {
    return (
      <nav className="sticky-top navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </div>
          <div id="navbar" className="navbar-collapse collapse">
            <div className="nav navbar-nav navbar-nav-scroll d-flex justify-content-center container">
              <a className="nav-item nav-link rounded-box" href="#home">
                Me
              </a>
              <a className="nav-item nav-link rounded-box" href="#about">
                Work
              </a>
              <a className="nav-item nav-link rounded-box" href="#projects">
                Experience
              </a>
              <a className="nav-item nav-link rounded-box" href="#skills">
                Skills
              </a>
              <a className="nav-item nav-link rounded-box" href="#testimonials">
                Blog
              </a>
              <a className="nav-item nav-link rounded-box" href="#contact">
                Contact
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavigationBar;

/**

 


 */
