import React, { Component } from "react";
import "../Css/Testimonials.css";

class Testimonials extends Component {
  render() {
    return (
      <div
        style={{ paddingTop: 100, paddingBottom: 20 }}
        className="container min-vh-100 position-relative"
      >
        <div className="row">
          <h2 className="text-danger">Blog</h2>
        </div>
        <div
          style={{ padding: 50 }}
          className="row d-flex justify-content-center"
        >
          <div className="testimonial-background d-flex justify-content-center align-self-center">
            <h5 className="text-secondary align-self-center">
              Work in progress...
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials;
