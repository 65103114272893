import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../Css/Contact.css";
import {
  FaFacebookSquare,
  FaGithubSquare,
  FaLinkedin,
  FaStackOverflow,
} from "react-icons/fa";

class Contact extends Component {
  downloadResume = () => {
    fetch(process.env.PUBLIC_URL + "resume.pdf").then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = "resume.pdf";
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  render() {
    return (
      <div className="bg-light">
        <div className="container" style={{ minHeight: 100, padding: 20 }}>
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <a target="_blank" href="https://facebook.com/themohitrao">
                <FaFacebookSquare
                  style={{ margin: 0 }}
                  className="social-icon"
                />
              </a>

              <a
                target="_blank"
                href="https://www.linkedin.com/in/themohitrao/"
              >
                <FaLinkedin className="social-icon" />
              </a>

              <FaGithubSquare className="social-icon" />
              <FaStackOverflow className="social-icon" />
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12 col-sm-12 col-md-12">
              <h7>
                <button className="download-cv" onClick={this.downloadResume}>
                  Download CV
                </button>
              </h7>
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12 col-sm-12 col-md-12 d-flex justify-content-center">
              <h7 className="bottom-menu-text-style">
                Copyright <span className="text-danger">©2021</span> All rights
                reserved.
              </h7>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
