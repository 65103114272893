import NavigationBar from "./Components/Navigationbar";
import Home from "./Components/Home";
import About from "./Components/About";
import Projects from "./Components/Projects";
import React, { Component } from "react";
import Skills from "./Components/Skills";
import Testimonials from "./Components/Testimonials";
import Contact from "./Components/Contact";

class App extends Component {
  render() {
    return (
      <div data-spy="scroll" data-target="#navbar">
        <NavigationBar className="position-absolute sticky-top" />
        <section id="home">
          <Home />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="testimonials">
          <Testimonials />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </div>
    );
  }
}

export default App;

/**

 */
