import React, { Component } from "react";
import "../Css/Skills.css";

class Skill extends Component {
  constructor(props) {
    super(props);
  }

  getHexCode(inputValue, randomNumber) {
    let totalValue = 0;

    var i = inputValue.length;
    while (i--) {
      totalValue += inputValue.charCodeAt(i);
    }
    console.log("totalValue  before random :: ", totalValue);
    totalValue *= randomNumber;
    const color = "#" + this.getCorrectRange(totalValue).toString(16) + "FF";
    console.log("color :: ", color);
    return color;
  }

  getCorrectTransparencyRange(value) {
    console.log("actualValue :: ", (value / 100) * 255);
    return ((value / 100) * 255).toString(16);
  }

  getCorrectRange(totalValue) {
    if (totalValue / 999999 >= 1) {
      return totalValue + 9000000;
    }
    if (totalValue / 99999 >= 1) {
      return totalValue + 9900000;
    }
    if (totalValue / 9999 >= 1) {
      return totalValue + 9990000;
    }
    if (totalValue / 999 >= 1) {
      return totalValue + 9999000;
    }
    if (totalValue / 99 >= 1) {
      return totalValue + 9999900;
    }
    if (totalValue / 9 >= 1) {
      return totalValue + 9999990;
    } else {
      return 9999999;
    }
  }

  render() {
    return (
      <div className="img-skill-container-style d-flex justify-content-center">
        <img
          className="img-skill-style"
          onClick={() => this.props.selectSkill(this.props.skill.id - 1)}
          src={this.props.skill.icon}
          alt=""
        />
      </div>
    );
  }
}

export default Skill;
