import React, { Component } from "react";
import "../Css/Home.css";
import "bootstrap/dist/css/bootstrap.css";

class Home extends Component {
  state = {};
  render() {
    return (
      <div className="Home-bg">
        <div className="bg-light half-screen" />

        <div className="adjust-profileIcon container d-flex justify-content-center">
          <div className="row align-items-center">
            <div className="col-sm d-flex justify-content-center">
              <a>
                <img
                  className="Logo"
                  alt=""
                  src={process.env.PUBLIC_URL + "profile_pic.png"}
                />
                <img
                  className="Logo"
                  alt=""
                  src={process.env.PUBLIC_URL + "profile_pic_two.png"}
                />
              </a>
            </div>
            <div className="col-sm d-flex justify-content-center text-header">
              <div>
                <h1 className="text-nowrap">
                  Software <span className="text-danger">Developer</span>
                </h1>
                <h4 className="text-nowrap fw-bold">
                  <span className="text-danger">U</span>nlearn.{" "}
                  <span className="text-danger">R</span>elearn.{" "}
                  <span className="text-danger">E</span>volve.
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="adjust-contactInfo container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-4 col-md-4  text-header container p-3">
              <div>
                <h4 className="text-nowrap">Design For</h4>
                <h4 className="fw-bold">
                  Mobile <span className="text-danger fw-light">|</span> Web{" "}
                  <span className="text-danger fw-light">| </span>Backend
                </h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-md-4 text-header container p-3">
              <div>
                <h4 className="text-nowrap">Phone</h4>
                <h4 className="fw-bold">
                  (+91) <span>999 970</span>{" "}
                  <span className="text-danger">2675</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-md-4  text-header container p-3">
              <div>
                <h4 className="text-nowrap">Email</h4>
                <h4 className="text-break fw-bold">
                  the<span className="text-danger">mohit</span>rao@gmail.com
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
