import React, { Component } from "react";
import Company from "./Company";
import "../Css/Projects.css";
import "bootstrap/dist/css/bootstrap.css";
import Project from "./Project";

class Projects extends Component {
  state = {
    selectedId: 0,

    companies: [
      { id: 1, name: "SKUAD", duration: "2 years" },
      { id: 2, name: "PRIMUS | Sapeint", duration: "7 Months" },
      { id: 3, name: "Star Mobileoid 2 Pvt Ltd", duration: "3.7 years" },
      { id: 4, name: "HCL", duration: "2 years" },
    ],

    projects: [
      {
        id: 1,
        companyId: 1,
        name: "Nigerian Supply Chain Giant",
        description: [
          "MVP development from strach.",
          "Features - Customer Onboarding, Prouct Selection, Order Creation, notifications.",
          "MVVM-clean, Dagger2, Rx, Coroutines, view binding., Room, Navigation Flow",
          "GraphQl, Crash Analytics",
        ],
      },
      {
        id: 2,
        companyId: 1,
        name: "Airtel Africa",
        description: [
          "Architecture Transformation, New Feature Development",
          "Features - 3rd party PayBills, Instant data/money loans",
          "MVVM-clean, Dagger2, Data Binding, Navigation Flow",
          "Less usage of external libraries",
        ],
      },
      {
        id: 3,
        companyId: 2,
        name: "Famous Fast Food Chain",
        description: [
          "Architecture Transformation, New Feature Development",
          "Features - Customer Loyalty Program",
          "MVVM-clean, Dagger2, Data Binding, Constraint Layout.",
          "Charles,Stash, Jenkins, Invision",
          "Coding along 200 plus android dev, First time experienced a master management process.",
        ],
      },
      {
        id: 4,
        companyId: 3,
        name: "Telecom Gaint - UAE",
        description: [
          "Code Devlopment From Scratch to production",
          "Intra Company Chat Communication Plaform with Role Management Integration.",
          "Data Sharing, data encryption, XMPP Framework",
          "Also worked on Node.js,Angular 4",
        ],
      },
      {
        id: 5,
        companyId: 3,
        name: "FRT - Ericson",
        description: [
          "Code Devlopment From Scratch to production",
          "Continuous Location reporting tool with device support for API 19 (Jellybean) and above",
          "Alarm manager, Job Schedular, Foreground Services",
        ],
      },
      {
        id: 6,
        companyId: 3,
        name: "Cardiac Connect",
        description: [
          "MVP development from strach.",
          "Overloaded with usage of launch modes and tasks, Fragment Flows.",
        ],
      },
      {
        id: 7,
        companyId: 3,
        name: "Reporting Tool - Ericson",
        description: [
          "Code Devlopment From Scratch to production",
          "Simple Attendence Form and a location based Reporting tool",
        ],
      },
      {
        id: 8,
        companyId: 3,
        name: "mNews - Choice",
        description: [
          "Code Devlopment From Scratch to production",
          "Intra Organisation Communication and News Sharing Platform",
          "Overloaded with usage of Co ordinator Layout, FragmentManager, Child Fragment Manager",
          "SqlChiper, Root Check, TLS Encryption",
        ],
      },
      {
        id: 9,
        companyId: 3,
        name: "NewsLetter App - IT Gaint",
        description: [
          "Code Devlopment From Scratch to production",
          "Intra Organisation App : dynamic content based UI generation",
        ],
      },
      {
        id: 10,
        companyId: 3,
        name: "Smart Hajj 2015 - Saudi Arabia",
        description: [
          "Code Devlopment From Scratch to production",
          "Features: Ritual Guidance, Mecca Compass, Weather Report, Traffic Update, Social Login, Maps, POIs, Messenger, Currency Convertor",
          "Offline Map support",
        ],
      },
      {
        id: 11,
        companyId: 4,
        name: "EMC Process",
        description: [
          "System Analyst in an internal B2B IT Desk for EMC Company.",
          "RDS Support, Exchange Support, RSA support, Oracle Support",
        ],
      },
      {
        id: 12,
        companyId: 4,
        name: "British Telecom (BT)",
        description: [
          "Technical Officer in an internation B2C Voice process.",
          "RDS Support, Line support, Yahoo Mail support, Anti virus Support",
        ],
      },
    ],
  };

  handleCompanySelection = (company) => {
    this.setState({
      selectedId: company.id,
      companies: this.state.companies,
      projects: this.state.projects,
    });
  };

  clearProjects = () => {
    this.setState({
      selectedId: 0,
      companies: this.state.companies,
      projects: this.state.projects,
    });
  };

  renderProjects() {
    if (this.state.selectedId === 0)
      return (
        <div className="col-lg-12 col-sm-12 col-md-12">
          <h2 className="text-wrap text-select-project-style">
            Cumlative Experience of over 8 years.
            <br />
            <br />
            Worked across dynamic platforms like E-Commerce, Location Tracking,
            Reporting, Tele-Communications, Dynamic Content Generation, Chat
            Platforms.
            <br />
            <br />
            Adept in working in a fast paced as well as process oriented
            environment.
            <br />
            <br />
            A Lone wolf guy, but in full synchronization when comes to Team
            Work.
            <br />
            <br />
            Flexible with work type, time, hierarchy, style, people, attitudes.
            The focal point is always one thing : Task Completion.
            <br />
            <br />
            Please select a Company for more details.
          </h2>
        </div>
      );

    let list = this.state.projects.filter(
      (project) => project.companyId === this.state.selectedId
    );

    if (list.length === 0) {
      return (
        <div className="col-lg-12 col-sm-12 col-md-12">
          <h2 className="text-wrap text-select-project-style">
            No Projects under Selected Company.
          </h2>
        </div>
      );
    }

    return list.map((project) => (
      <div
        key={project.id}
        className="col-lg-3 col-sm-3 col-md-3 col-projects-style"
      >
        <div className="col-content-projects-style">
          <Project project={project} />
        </div>
      </div>
    ));
  }

  renderClassForCompany = (id) => {
    const className = "box-company-";
    if (id === this.state.selectedId) {
      return className + "selected";
    }
    return className + "unselected";
  };

  renderCompanies() {
    return this.state.companies.map((company) => (
      <div
        key={company.id}
        style={{ padding: 20 }}
        className="col-lg-3 col-sm-3 col-md-3"
        onClick={() => this.handleCompanySelection(company)}
      >
        <div className={this.renderClassForCompany(company.id)}>
          <Company company={company} />
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div style={{ paddingTop: 100 }} className=" container min-vh-100">
        <div className="row">
          <h2 style={{ cursor: "pointer" }} onClick={this.clearProjects}>
            Work <span className="text-danger">Experience</span>
          </h2>
        </div>
        <div className="project-highlight row row-projects-style bg-light text-format">
          {this.renderProjects()}
        </div>
        <div style={{ paddingTop: 50 }} className="row no-gutters text-format">
          {this.renderCompanies()}
        </div>
      </div>
    );
  }
}

export default Projects;
